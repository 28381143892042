<template>
  <b-card>
    <div class="custom-container">
      {{ mapaFull }}<br />

      <div class="row justify-content-center" style="gap: 1rem">
        <div v-for="(seccion, index) in secciones" :key="index" class="bg-dark rounded p-3 col-12 col-xl-3">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <span @click="cambiarEstadoGrupo(seccion.id, seccion.items)" class="h3 font-weight-bold">{{ seccion.group }}</span>
            <div class="rounded-circle border" style="width: 50px; height: 50px; display: grid; place-items: center">
              <img :src="seccion.icon" width="30px" />
            </div>
          </div>
          <div class="d-flex flex-wrap">
            {{ index }}
            <vs-button
              :flat="mapa[index][itemIndex] == 1 ? false : true"
              :gradient="mapa[index][itemIndex] == 1 ? true : false"
              :danger="mapa[index][itemIndex] == 1 ? false : true"
              @click="cambiarAcceso(index, itemIndex, item, seccion)"
              v-for="(item, itemIndex) in seccion.items"
              :key="item.title"
            >
              {{ item.title }}
            </vs-button>
          </div>
        </div>
      </div>
      <div class="text-right px-5 pb-4 d-flex justify-content-end">
        <vs-button  primary icon animation-type="vertical" type="submit" class="mx-1" @click="saveData">
          <i class="fas fa-save pr-1"></i>
          Guardar Información
          <template #animate>
            <i class="fas fa-save"></i>
          </template>
        </vs-button>
        <vs-button  danger icon animation-type="vertical" class="mx-1" @click="$router.push('/helex/persona')">
          <i class="fas fa-xmark pr-1"></i>
          Cancelar
          <template #animate>
            <i class="fas fa-xmark"></i>
          </template>
        </vs-button>
      </div>
    </div>
  </b-card>
</template>

<script>
const Rutas = require("@/components/partials/backend/HeaderStyle/groups.json").data;
import Swal from "sweetalert2";

export default {
  name: "MiComponente",
  data() {
    return {
      secciones: [...Rutas],
      PersonaId: null,
      Persona: {},
      mapa: [],
      mapaFull: [],
    };
  },
  async mounted() {
    this.PersonaId = this.$route.params.PersonaId;
    await this.getData();
    if (this.Persona.mapa != null) {
      let mapaFull = this.Persona.mapa.split(",");
      for await (let item of mapaFull) {
        this.mapaFull.push([item]);
      }
      let contadorRuta = 0; //Rutas.length;
      for await (let it of Rutas) {
        this.mapa.push([this.mapaFull[contadorRuta]]);
        let contador = 0;
        if (Object.prototype.hasOwnProperty.call(it, "items") && it.items.length > 0) {
          for await (let el of it.items) {
            this.mapa[contadorRuta][contador] = this.mapaFull[el.ordenAcceso];
            contador++;

          }
        }
        contadorRuta++;
      }
    } else {
      for await (let it of Rutas) {
        console.log(it);
        this.mapa.push([]);
        this.mapaFull.push([0]);
        if (Object.prototype.hasOwnProperty.call(it, "items") && it.items.length > 0) {
          for await (let el of it.items) {
            console.log(el);
            this.mapaFull.push([0]);
          }
        }
      }
    }
    this.$forceUpdate();
  },
  methods: {
    async getData() {
      try {
        let res = await this.$store.getters.fetchGet( {
          path: "Persona/GetPersonaById/" + this.PersonaId,
        });
        this.Persona = await res.json();
        console.log("informacion", this.Persona);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Por favor recarga la pagina",
          icon: "error",
          confirmButtonText: "Voy a recargar la pagina"
        })
        //this.Persona = {};
        console.log("informacion error", error);
      }
    },
    async saveData() {
      console.log("Guardando", this.Persona);
      try {
        this.Persona.mapa = this.mapaFull.toString();
        let res = await this.$store.dispatch("hl_post", {
          path: "Persona/Update",
          data: this.Persona,
        });
        Swal.fire({
          title: "Guardado Exitoso",
          text: "Se ha guardado los cambios exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar"
        }).then(()=>{
        if (res) {
          location.reload()
        } else {
          alert("Verificar");
        }
        })
        console.log("post mapa...", res);

      } catch (error) {
        console.error("Error al guardar mapa:", error);
      }
    },
    toggleSection(index) {
      this.secciones.forEach((seccion, i) => {
        seccion.expandida = i === index && !seccion.expandida;
      });
    },

    cambiarEstadoGrupo(indexGroup, items) {
      if (indexGroup == 1) {
        this.mapaFull[0] = this.mapaFull[0] == 0? 1 : 0
        alert (this.mapaFull[0])
      } else {
        // alert (items[0].ordenAcceso - 1)
        this.mapaFull[items[0].ordenAcceso - 1] = this.mapaFull[items[0].ordenAcceso - 1] == 0? 1 : 0
      }
      console.log("indexgroup", indexGroup);
      console.log(items);
      let indice = this.secciones.findIndex((x) => x.id == indexGroup);
      console.log("---->", indice);

      for (let index = 0; index < items.length; index++) {
        this.cambiarAcceso(indice, index, items[index]);
      }
    },
    cambiarAcceso(index, itemIndex, item, seccion) {
      this.secciones = JSON.parse(JSON.stringify(this.secciones));
      this.mapa[index][itemIndex] = this.mapa[index][itemIndex] == 1 ? 0 : 1;
      this.mapaFull[item.ordenAcceso] = this.mapa[index][itemIndex];
      if (seccion != undefined && this.mapa[index][itemIndex] == 1) {
        
        this.mapaFull[seccion.items[0].ordenAcceso - 1] = 1
      }
      },
  },
};
</script>
<style scoped></style>
